import React from "react";

import BonusPoint from "./../bonuspoint/bonuspoint";
import TwoBox from "./../twopoint/twopoint";
import HomeBanner from "./../homebanner/homebanner";
import RewardsWrapper from "./../rewardswrapper/rewardswrapper";
import homeData from "./../../data/home.json";
import dashboardData from "./../../data/dashboard.json";
import RewardsDashboard from "../dashboard/dashboard";
import GetRewarded from "../getRewarded/getRewarded";
import RedeemRewards from "../redeemRewards/redeemRewards";
import { useStoreState } from "easy-peasy";
import RewardsCarousel from "../rewardsCarousel/rewardsCarousel";
import Disclosure from "../disclosure/disclosure";
import PromotionBanner from "../promotionalBanner/promotionalBanner";

const Home = (props) => {
  document.title = props.title;
  const getUserValue = useStoreState((state) => state.user.userInfo); 

  return (
    <>
      {getUserValue ? ( 
        // Show this if user is authenticated
        <>
        <PromotionBanner
            userValue={getUserValue}
            catalogUrl={global.catalogUrl}
            promotions={[
              { startDate: "01/01/2025, 12:01:00 AM", endDate: "02/18/2025, 11:59:00 PM", imgClass: "PNC_040125_Front_Porch_Banner_360x55_High", promoUrl: 'GiftCard&shop=FuelUp25'},
              { startDate: "02/19/2025, 12:01:00 AM", endDate: "03/31/2025, 11:59:00 PM", imgClass: "PNC_040125_Front_Porch_Banner_360x55_High", promoUrl: 'GiftCard&shop=AprilGC'},
              { startDate: "04/01/2025, 12:01:00 AM", endDate: "04/30/2025, 11:59:00 PM", imgClass: "PNC_040125_Front_Porch_Banner_360x55_High", promoUrl: 'GiftCard&shop=AprilGC'},
              { startDate: "05/01/2025, 12:01:00 AM", endDate: "06/05/2025, 11:59:00 PM", imgClass: "brown-porch-feb", promoUrl: 'gift'},
              { startDate: "06/06/2025, 12:01:00 AM", endDate: "07/07/2025, 11:59:00 PM", imgClass: "brown-porch-feb", promoUrl: 'gift'},
              { startDate: "07/08/2025, 12:01:00 AM", endDate: "07/31/2025, 11:59:00 PM", imgClass: "brown-porch-feb", promoUrl: 'gift'},
              { startDate: "08/01/2025, 12:01:00 AM", endDate: "09/03/2025, 11:59:00 PM", imgClass: "brown-porch-feb", promoUrl: 'gift'},
              { startDate: "09/04/2025, 12:01:00 AM", endDate: "09/30/2025, 11:59:00 PM", imgClass: "brown-porch-feb", promoUrl: 'gift'},
              { startDate: "10/01/2025, 12:01:00 AM", endDate: "11/04/2025, 11:59:00 PM", imgClass: "brown-porch-feb", promoUrl: 'gift'},
              { startDate: "11/05/2025, 12:01:00 AM", endDate: "12/18/2025, 11:59:00 PM", imgClass: "brown-porch-feb", promoUrl: 'gift'},
            ]}
          />

          <RewardsDashboard
            dashboardData={dashboardData.dashboardData}
            rewardsBalance={52500}
            pointsEarned={2600}
          />
          <RewardsCarousel />
          <RedeemRewards />
          <GetRewarded />
          <Disclosure/>
        </>
      ) : (
        // Show this if user is not authenticated
        <>
          <HomeBanner homeBanner={homeData.homeBanner} />
          <TwoBox boxData={homeData.boxData} />
          <BonusPoint />
          <RewardsWrapper rewards={homeData.rewards} />
          <Disclosure/>
        </>
      )}
    </>
  );
};

export default Home;
