import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { useStoreState, useStoreActions } from "easy-peasy";
import { read_cookie } from "../../read_cookie";
import IdleMonitor from "../idleMonitor";
import "./header.css";
import { toSentenceCase } from "../../utils/stringUtils"; 

const Header = () => {
  const location = useLocation();
  const [activePath, setActivePath] = useState(location.pathname);
  const [activeExternal, setActiveExternal] = useState(null);
  const getTokenCall = useStoreActions((actions) => actions.token.getToken);
  const getUserInfo = useStoreActions((actions) => actions.user.getUser);
  const getUserValue = useStoreState((state) => state.user.userInfo);
  const getMessagesac = useStoreActions((actions) => actions.messages.getMessages);
  const getMessagesValue = useStoreState((state) => state.messages.messagesInfo);

  const [unreadCount, setUnreadCount] = useState(0);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const navigate = useNavigate();

  let messageText;
  if (unreadCount === 1) {
    messageText = `${unreadCount} New Message`;
  } else if (unreadCount !== 0) {
    messageText = `${unreadCount} New Messages`;
  } else {
    messageText = "Messages";
  }

  const handleMouseEnter = (dropdownId) => {
    updateMessagesCount();
    if (window.innerWidth > 990) {
      setActiveDropdown(dropdownId);
    }
  };

  const handleMouseLeave = () => {
    if (window.innerWidth > 990) {
      setActiveDropdown(null);
    }
  };

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const handleNavLinkClick = (path) => {
    setActivePath(path);
    setActiveExternal(null);
    setActiveDropdown(null);
    setIsExpanded(false);
  };

  const handleExternalLinkClick = (event, linkName) => {
    event.stopPropagation();
    setActiveExternal(linkName);
    setActivePath(null);
    setActiveDropdown(null);
    setIsExpanded(false);
  };

  const handleDropdownClick = (dropdownId) => {
    if (window.innerWidth <= 990) {
      setActivePath(null);
      setActiveExternal(null);
      setActiveDropdown((prevState) => (prevState === dropdownId ? null : dropdownId));
    }
  };

  useEffect(() => {
    const fullUrl = window.location.href;
    const fullArr = fullUrl.split("?");
    if (fullArr[1] && localStorage.userInfo) {
      localStorage.clear();
      window.location.reload();
    } else {
      getTokenCall();
    }
    getUserInfo(read_cookie("pnc-rewards-token"));
  }, [getTokenCall, getUserInfo]);

  useEffect(() => {
    if (getMessagesValue && getMessagesValue.length > 1) {
      const unreadArr = getMessagesValue.filter((msg) => msg.IsRead === "N");
      localStorage.unreadMsgCount = unreadArr.length;
      if (unreadArr.length !== unreadCount) {
        setUnreadCount(unreadArr.length);
      }
    }
  }, [getMessagesValue, unreadCount]);

  const updateMessagesCount = () => {
    getMessagesac(read_cookie("pnc-rewards-token"));
  };

  useEffect(() => {
    // Set active path based on location.pathname
    const pathSegments = location.pathname.split("/");
    let relevantPath = "";
    if (location.pathname === "/") {
      relevantPath = "/";
    } else {
      relevantPath = pathSegments[1];
    }

    setActivePath(relevantPath);
  }, [location.pathname]);

  //backbutton logic
  const updateNavigationHistory = (currentPath) => {
    if (currentPath.includes('/resp') || currentPath.includes('/Logout') || currentPath.includes('/SAML')) {
      return;
    }

    const history = JSON.parse(sessionStorage.getItem('navigationHistory')) || [];
    if (history.length === 0 || history[history.length - 1] !== currentPath) {
      history.push(currentPath);
      sessionStorage.setItem('navigationHistory', JSON.stringify(history));
    }
  };

  const getPreviousPath = () => {
    const history = JSON.parse(sessionStorage.getItem('navigationHistory')) || [];
    if (history.length > 1) {
      history.pop();
    }
    sessionStorage.setItem('navigationHistory', JSON.stringify(history));
    return history[history.length - 1] || null;
  };

  const handleBackButtonClick = () => {
    const previousPath = getPreviousPath();
    if (previousPath) {
      navigate(previousPath);
    }
  };

  const shouldShowBackButton = () => {
    const history = JSON.parse(sessionStorage.getItem('navigationHistory')) || [];
    return history.length > 1;  
  };

  useEffect(() => {
    updateNavigationHistory(location.pathname);
  }, [location]);

  return (
    <div id="pnc-header">
      {getUserValue.FirstName && <IdleMonitor />}
      <header>
        <Navbar expand="lg" expanded={isExpanded}>
          <Container fluid className="pnc-top-navbar">
            <Navbar.Toggle aria-controls="navbarCollapse" onClick={handleToggle} />
            {getUserValue.FirstName && shouldShowBackButton() && (
              <Nav.Link
                id="backButtonDesktop"
                onClick={handleBackButtonClick}
                className="pnc-return-to-account pnc-show-desktop"
              >
                Back
              </Nav.Link>
            )}
            <Navbar.Brand as={Link} to="/" className="pnc-logo-container">
              <img className="pnc-navbar-logo" src="/images/PNC_Onyx_white.png" alt="PNC logo" />
            </Navbar.Brand>
            {getUserValue.FirstName && (
              <Navbar.Text className="pnc-user-info">
                Hi, <span className="pnc-user-name">{toSentenceCase(getUserValue.FirstName.trim())}</span>
                <div className="pnc-user-points">
                {getUserValue.JsonExternalData.CurrentBalance ? Math.round(getUserValue.JsonExternalData.CurrentBalance).toLocaleString() + " Points" : ""}
                </div>
              </Navbar.Text>
            )}
          </Container>
          <Container className="pnc-main-navbar">
            <Navbar.Collapse id="navbarCollapse">
              <Nav className="pnc-main-nav">
                <Nav.Link
                  as={Link}
                  to="/"
                  aria-label="HOME"
                  className={`pnc-nav-link ${activePath === "/" ? "active" : ""}`}
                  onClick={() => handleNavLinkClick("/")}
                >
                  HOME
                </Nav.Link>
                <Nav.Link
                  href={
                    getUserValue.FirstName
                      ? global.catalogUrl + "/b2r/SAML/login?supplier=Travel"
                      : global.catalogUrl + "/b2r/landingHome.do?anon=1&supplier=Travel"
                  }
                  aria-label="TRAVEL"
                  className={`pnc-nav-link ${activeExternal === "Travel" ? "active" : ""}`}
                  onClick={(e) => handleExternalLinkClick(e, "Travel")}
                >
                  TRAVEL
                </Nav.Link>
                <Nav.Link
                  href={
                    getUserValue.FirstName
                      ? global.catalogUrl + "/b2r/SAML/login?supplier=GiftCard"
                      : global.catalogUrl + "/b2r/landingHome.do?anon=1&supplier=GiftCard"
                  }
                  aria-label="GIFT CARDS"
                  className={`pnc-nav-link ${activeExternal === "GiftCards" ? "active" : ""}`}
                  onClick={(e) => handleExternalLinkClick(e, "GiftCards")}
                >
                  GIFT CARDS
                </Nav.Link>
                <NavDropdown
                  title="SHOP"
                  id="shop"
                  className={`pnc-nav-dropdown ${
                    activePath === "Shop" ? "active" : ""
                   }`}
                  show={activeDropdown === "shop"}
                  onMouseEnter={() => handleMouseEnter("shop")}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleDropdownClick("shop")}
                >
                <div className="pnc-up-arrow"></div>
                <NavDropdown.Item
                  as={Link}
                  to={
                    getUserValue.FirstName
                      ? global.catalogUrl + "/b2r/landingHome.do?supplier=Apple"
                      : global.catalogUrl + "/b2r/landingHome.do?anon=1&supplier=Apple"
                  }
                  aria-label="Shop Apple"
                  onClick={() => handleNavLinkClick("Shop")}
                >
                  Shop Apple
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  to={
                    getUserValue.FirstName
                      ? global.catalogUrl + "/b2r/landingHome.do?supplier=Merchandise"
                      : global.catalogUrl + "/b2r/landingHome.do?anon=1&supplier=Merchandise"
                  }
                  onClick={() => handleNavLinkClick("Shop")}
                >
                  Shop Marketplace
                </NavDropdown.Item>
                </NavDropdown>
                {getUserValue.FirstName && (
                  <NavDropdown
                    title="REWARDS DETAILS"
                    id="rewardsDetails"
                    className={`pnc-nav-dropdown ${
                      activePath === "RewardsDetails" ? "active" : ""
                    }`}
                    show={activeDropdown === "rewardsDetails"}
                    onMouseEnter={() => handleMouseEnter("rewardsDetails")}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleDropdownClick("rewardsDetails")}
                  >
                    <div className="pnc-up-arrow"></div>
                    <NavDropdown.Item
                      as={Link}
                      to="/RewardsDetails/RewardsOverview"
                      aria-label="Rewards Overview"
                      onClick={() => handleNavLinkClick("RewardsDetails")}
                      reloadDocument
                    >
                      Rewards Overview
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/RewardsDetails/Transactions"
                      aria-label="Transactions"
                      onClick={() => handleNavLinkClick("RewardsDetails")}
                      reloadDocument
                    >
                      Transactions
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/RewardsDetails/OrderHistory"
                      aria-label="Reward Order History"
                      onClick={() => handleNavLinkClick("RewardsDetails")}
                      reloadDocument
                    >
                      Reward Order History
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/RewardsDetails/Benefits"
                      aria-label="Benefits"
                      onClick={() => handleNavLinkClick("RewardsDetails")}
                      reloadDocument
                    >
                      Benefits
                    </NavDropdown.Item>
                  </NavDropdown>
                )}

                {getUserValue.FirstName && (
                  <NavDropdown
                    title="MY PROFILE"
                    id="profileDropdown"
                    className={`pnc-nav-dropdown ${activePath === "MyProfile" ? "active" : ""}`}
                    show={activeDropdown === "profileDropdown"}
                    onMouseEnter={() => handleMouseEnter("profileDropdown")}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleDropdownClick("profileDropdown")}
                  >
                    <div className="pnc-up-arrow"></div>
                    <NavDropdown.Item
                      as={Link}
                      to="/MyProfile/Profile"
                      aria-label="Your Profile"
                      onClick={() => handleNavLinkClick("MyProfile")}
                      reloadDocument
                    >
                      My Profile
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/MyProfile/LinkedCards"
                      aria-label="Linked Cards"
                      onClick={() => handleNavLinkClick("MyProfile")}
                      reloadDocument
                    >
                      Linked Cards
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/MyProfile/Communications"
                      aria-label="Communications Preferences"
                      onClick={() => handleNavLinkClick("MyProfile")}
                      reloadDocument
                    >
                      Communications Preferences
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/MyProfile/Messaging"
                      aria-label="Messages"
                      onClick={() => handleNavLinkClick("MyProfile")}
                      reloadDocument
                    >
                      {messageText}
                    </NavDropdown.Item>
                  </NavDropdown>
                )}

                {getUserValue.FirstName && (
                  <NavDropdown
                    title="HELP"
                    id="helpDropdown"
                    className={`pnc-nav-dropdown ${activePath === "Help" ? "active" : ""}`}
                    show={activeDropdown === "helpDropdown"}
                    onMouseEnter={() => handleMouseEnter("helpDropdown")}
                    onMouseLeave={handleMouseLeave}
                    onClick={() => handleDropdownClick("helpDropdown")}
                  >
                    <div className="pnc-up-arrow"></div>
                    <NavDropdown.Item
                      as={Link}
                      to="/Help/FAQs"
                      aria-label="FAQs"
                      onClick={() => handleNavLinkClick("Help")}
                    >
                      FAQs
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/Help/ContactUs"
                      aria-label="Contact Us"
                      onClick={() => handleNavLinkClick("Help")}
                    >
                      Contact Us
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to="/OldHome"
                      aria-label="Old Home"
                      onClick={() => handleNavLinkClick("Help")}
                    >
                      Old Home
                    </NavDropdown.Item>   
                  </NavDropdown>
                )}

                {getUserValue.FirstName ? (
                  <Nav.Link
                    href="/Logout/Logout"
                    className="pnc-sign-out pnc-nav-link"
                    onClick={() => handleNavLinkClick("Logout")}
                  >
                    <span>SIGN OUT</span>
                  </Nav.Link>
                ) : (
                  <Nav.Link
                    href="https://www.pnc.com/en/personal-banking.html"
                    className="pnc-sign-out pnc-nav-link"
                    onClick={() => handleNavLinkClick("Login")}
                  >
                    <span>SIGN IN</span>
                  </Nav.Link>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
          {getUserValue.FirstName && shouldShowBackButton() && (
            <Nav.Link
              id="backButtonMobile"
              onClick={handleBackButtonClick}
              className="pnc-return-to-account pnc-show-mobile"
            >
              Back
            </Nav.Link>
          )}
        </Navbar>
      </header>
    </div>
  );
};

export default Header;
